<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Cont client" subtitle="Login la un cont client selectat">
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <!-- Latest Customers -->
        <b-col xl="8" offset-xl="2">
          <base-block title="Selecteaza cont client" header-bg content-full>
            <b-form @submit.stop.prevent="onImpersonateSubmit">
              <b-row>
                <b-col xl="5">
                  <b-form-group>
                    <v-select @input="companySelected" required v-bind:class="{'is-invalid': $v.impersonateForm.company.$error}" data-label="Companie" @search="fetchCompanyOptions" :options="companyOptions" placeholder="Cauta dupa partener" v-model="$v.impersonateForm.company.$model" aria-describedby="company-feedback">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Nu am gasit nici un rezultat
                    </span>
                    </v-select>
                    <b-form-invalid-feedback v-if="!$v.impersonateForm.company.required" id="company-feedback">
                      Campul este obligatoriu!
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col xl="5">
                  <b-form-group>
                    <v-select required v-bind:class="{'is-invalid': $v.impersonateForm.user.$error}" data-label="Utilizator" @search="fetchUserOptions" :options="userOptions" placeholder="Cauta dupa utilizator" v-model="$v.impersonateForm.user.$model" aria-describedby="user-feedback">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Nu am gasit nici un rezultat
                    </span>
                    </v-select>
                    <b-form-invalid-feedback v-if="!$v.impersonateForm.user.required" id="user-feedback">
                      Campul este obligatoriu!
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-button type="submit" variant="primary" block>
                    <i class="fa fa-fw fa-check-circle mr-1"></i> Login
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style lang="scss">
@import './src/assets/scss/vendor/vue-color';
@import '~vue-select/src/scss/vue-select';
@import './src/assets/scss/vendor/vue-select';
</style>

<script>
import {required} from "vuelidate/lib/validators";
import {ITEM_NUMBER_PER_PAGE} from "../../../constants/settings";
import {fleshErrorMessage} from "../../../error/server-error";
import {COMPANY_LIST_FOR_CONTROL} from "../../../constants/impersonate-graphql";
import VueSelect from "vue-select";
import {validationMixin} from "vuelidate";
import {GET_COMPANY_CHOICES} from "../../../constants/company-graphql";
import {IMPERSONATE, USER_SELECT_OPTIONS} from "../../../constants/user-graphql";
import {handleImpersonateResponse} from "../../../constants/helpers/impersonate-helper";

export default {
  name: "Impersonate",
  components: {'v-select': VueSelect},
  mixins: [validationMixin],
  data() {
    return {
      dataLoad: true,
      searchClickAttention: null,
      companyOptions: [],
      userOptions: [],
      impersonateForm: {
        company: null,
        user: null,
      },
    }
  },
  validations: {
    impersonateForm: {
      company: {
        required
      },
      user: {
        required
      },
    }
  },
  apollo: {
    companies: {
      query: COMPANY_LIST_FOR_CONTROL,
      fetchPolicy: 'no-cache',
      variables: {
        first: ITEM_NUMBER_PER_PAGE,
      },
      result (result) {
        let options = [];
        result.data.companies.edges.forEach(function (item) {
          options.push(item.node);
        });
        this.companyOptions = options;
      },
      error (error) {
        fleshErrorMessage(error, this);
      }
    }
  },
  methods: {
    onImpersonateSubmit() {
      this.$v.impersonateForm.$touch()
      if (this.$v.impersonateForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: IMPERSONATE,
        variables: {
          'userId': this.$v.impersonateForm.user.$model.value,
        }
      }).then((result) => {
        this.flashMessage.info({message: "Login in cont client rezolvat cu succes!"});
        handleImpersonateResponse(this, result.data.createImpersonate.impersonate.name, result.data.createImpersonate.impersonate.response);
        this.$router.push('/')
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    fetchCompanyOptions(search) {
      this.$apollo.query({
        query: GET_COMPANY_CHOICES,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          filter: search,
        }
      }).then((result) => {
        let options = [];
        result.data.companies.edges.forEach(function (item) {
          options.push(item.node);
        });
        this.companyOptions = options;
      }).catch((error) => {
        fleshErrorMessage(error);
      });
    },
    fetchUserOptions(search) {
      this.$apollo.query({
        query: USER_SELECT_OPTIONS,
        variables: {
          company: this.$v.impersonateForm.company.$model.value,
          name: search,
        }
      }).then((result) => {
        let options = [];
        result.data.users.forEach(function (item) {
          options.push(item);
        });
        this.userOptions = options;
      }).catch((error) => {
        fleshErrorMessage(error);
      });
    },
    companySelected() {
      this.impersonateForm.user = null;
      this.fetchUserOptions(null);
    },
  }
}
</script>