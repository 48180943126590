import gql from "graphql-tag/src";

export const COMPANY_LIST_FOR_CONTROL = gql`
query GetCompanyListForControl($first: Int, $after: String) {
  companies(first: $first, after: $after) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        value: _id
        label: companyName
      }
    }  
  }
}    
`